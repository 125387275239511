<template>
  <v-navigation-drawer v-model="drawerOpen" absolute bottom class="elevation-24" height="100vh" temporary
                       width="300px" @input="onInput">

    <v-list dense nav>

      <v-list-group
          v-for="drawerItem in drawerItems"
          :key="drawerItem.code"
          v-model="drawerItem.active"
          :append-icon="drawerItem.appendIcon"
          class="pl-5"
      >
        <v-divider v-if="drawerItem.items" class="mx-3 mb-3"></v-divider>

        <v-list-item slot="activator" :to="drawerItem.path">

          <v-list-item-icon v-if="!drawerItem.separator">
            <v-icon v-text="drawerItem.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content v-if="!drawerItem.separator">
            <v-list-item-title style="transition-duration: 0.1s">{{
                $vuetify.lang.t(drawerItem.label)
              }}
            </v-list-item-title>
          </v-list-item-content>

          <v-col v-if="drawerItem.separator" class="pl-0" cols="12">
            <v-divider class="mx-3 mb-3"></v-divider>
            <v-row v-if="drawerItem.text" class="text-center align-content-center">
              <div class="ma-auto">{{ $vuetify.lang.t('$vuetify.docAPI') }}</div>
            </v-row>
          </v-col>

        </v-list-item>

        <v-list-item
            v-for="item in drawerItem.items"
            :key="item.code"
            :to="item.path" class="pl-12">

          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $vuetify.lang.t(item.label) }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
        <v-divider v-if="drawerItem.items" class="mx-3 mb-3"></v-divider>

      </v-list-group>

    </v-list>
  </v-navigation-drawer>
</template>

<style>
a.v-list-item--active.v-list-item.v-list-item--link.v-list-item--two-line.theme--dark {
  color: #393f43 !important;
}

.v-application .title {
  color: white;
}
</style>

<script>

// Get APIs List
import apisList from '@/apis.json'

export default {
  name: 'CoreDrawer',
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      drawerOpen: this.open,
      drawerItems: apisList

    }
  },
  watch: {
    open (newVal) {
      this.drawerOpen = newVal
    }
  },
  methods: {
    onInput (isOpen) {
      this.$emit('drawerOpened', isOpen)
    }
  }
}
</script>
